export const en = {
  "errors": {
    "noWalletAvailable": "No wallet available. Please connect a Solana wallet!",
    "tokenNameInvalid": "Token Name can only contain letters and numbers, and its length must be greater than zero;",
    "tokenSymbolInvalid": "Token Symbol can only contain letters and numbers, and its length must be greater than zero; ",
    "supplyMustBeGreaterThanZero": "Supply must be greater than zero.",
    "creationError": "Error on create {tokenName}: {errorMessage}",
    "emptyTokenNameOrSymbol":"Attention! You are creating metadata with Token's NAME or SYMBOL which are empty text!"
  },
  "messages": {
    "uploading": "Uploading....",
    "uploadError": "Error on uploading: {errorMessage}",
    "uploadComplete": "Upload complete!",
    "wellDoneCreateToken":"Well done! Token {symbol} created!",
    "metadataNoExtension":"Exitensions will be add, if u want to add a exitension, please click add button",
    "metadataKeepCreatorNoneTip":"if u dont't want to add any creatorinfo in meta, please keep them both in None-Text",
    
  },
  "titles": {
    "solanaTokenCreator": "Token Creator",
    "claimCollector": "Claim your rewards",
    "createMetadata": "Create MetaData",
  },
  "descriptions": {
    "solanaTokenCreator": "Simple, user friendly, and fast.",
    "claimCollector": "This is what you deserve."
  },
  "actions": {
    "debugUseTestForm": "Debug: Use Test Form",
    "upload":"Upload",
    "edit":"Edit",
    "createToken":"Create {tokenSymbol} Token",
    "updateMetadata":"Update Metadata"
  },
  "labels": {
    "tokenName": "Token Name",
    "claimAccountInfo": "Account Info",
    "tokenSymbol": "Symbol",
    "tokenDecimals": "Decimals",
    "tokenSupply": "Supply",
    "tokenMetadataURI": "Token",
    "metadataImage":"Image",
    "metadataDescription":"Description",
    "metadataExtensions":"Extensions",
    "metadataCreatorInfo":"Creator Info",
    "metadataCreatorName":"Creator Name",
    "metadataCreatorSite":"Creator Site",
    "metadataTags":"Tags",
    "tokenInfo":"Token Info"
  },
  "hints": {
    "tokenName": "A good-sounding name really matters",
    "tokenSymbol": "Less is more",
    "tokenDecimals": "Meme token's decimals always is 9",
    "tokenSupply": "Determine the Supply of your Token",
    "tokenMetadataURI": "Point to your metadata url",
    "claimAccountInfo": "The more you pay, the more you claim",
    "metadataImage":"Upload your token's image here",
    "metadataDescription":"",
    "metadataExtensions":"",
    "metadataNewTag":"New Tag Name"
  },
  "nouns":{
    "metadata":"Metadata",
    "or":"OR",
    "connect":"Connect",
    "yourWallet":"your Solana wallet"
  },
  "article":{
    "tokenCreatorDesc":{
      "title":"Create Solana Token",
      "para1":"Effortlessly create your Solana SPL Token with our 7+1 step process no-coding required.",
      "para2":"Customize your Solana Token exactly the way you envision it. Less than 5 minutes, at an affordable cost."
    },
    "tokenCreatorUsage":{
      "title":"How to use Solana Token Creator",
      "desc":"Easily create your Solana token in seven steps:",
      "para2":"Enter your token name and symbol.",
      "para3":"Enter your token's precision.",
      "para4":"Enter the supply amount of your token.",
      "para5":"Enter your token metadata, and generate a unique identifier (URI) for your metadata.",
      "para6":"Click to create the token and wait for the Phantom Wallet transaction to pop up.",
      "para7":"Confirm the transaction and pay a fee of no more than 0.5 SOL, then wait for the transaction to complete to view your token. Also, you can see the token balance of the corresponding supply amount in your wallet. If it does not show up, you may need to wait a few minutes.",
      "foot1":"If you need to freeze minting permissions, update metadata, or use other features, you can also use the tools provided by this website.",
      "foot2":"If you encounter any problems or have any suggestions, feel free to join our Telegram group.",
      "foot3":"If you are satisfied with the use, you are welcome to recommend it to your friends, and we will provide you with a 20% income commission."
    }
  }
}
