import axios from "axios";

const pinataConfig = {
    root: 'https://api.pinata.cloud',
    headers: {
        'pinata_api_key': "00b12d2cfc49375c7300",
        'pinata_secret_api_key': "1ec5e9e545bc7614c0ae52bd78511328510483f5fe9781fc8fc867b35fa70be5"
    }
};


export async function uploadFileToIPFS(file: File) {
    if (file !== undefined) {
        const formData = new FormData();
        console.log(file)
        formData.append('file', file);
        const pinataBody = {
            options: {
                cidVersion: 1,
            },
            metadata: {
                name: file.name,
            }
        }
        formData.append('pinataOptions', JSON.stringify(pinataBody.options));
        formData.append('pinataMetadata', JSON.stringify(pinataBody.metadata));
        const url = `${pinataConfig.root}/pinning/pinFileToIPFS`;
        const response = await axios({
            method: 'post',
            url: url,
            data: formData,
            headers: pinataConfig.headers
        })
        if (response.data["IpfsHash"]) {
            return `https://brown-big-mackerel-960.mypinata.cloud/ipfs/${response.data["IpfsHash"]}`
        }
    }
}

export async function uploadObjectToIPFS(obj: Object) {
    if (obj !== undefined) {
        const pinataBody = {
            options: {
                cidVersion: 1,
            },
            metadata: {
                name: "pinnie.json",
            }
        }
        const url = `${pinataConfig.root}/pinning/pinJsonToIPFS`;
        const response = await axios({
            method: 'post',
            url: url,
            data: {
                pinataOptions: pinataBody.options,
                pinataMetadata: pinataBody.metadata,
                pinataContent: obj
            },
            headers: {
                ...pinataConfig.headers,
                "Content-Type": "application/json"
            }
        })
        if (response.data["IpfsHash"]) {
            return `https://brown-big-mackerel-960.mypinata.cloud/ipfs/${response.data["IpfsHash"]}`
        }
    }
}