import { createRouter, createWebHistory } from 'vue-router'

import IndexPage from '@/pages/IndexPage.vue'
import ClaimPage from '@/pages/ClaimPage.vue'
import TokenManagePage from '@/pages/TokenManagePage.vue'
import DemoPage from '@/pages/DemoPage.vue'
import { useStore } from '@/store'
export const routes = [
  {
    path: '/',
    component: IndexPage,
    meta: {
      title: 'Minthe: Solana Token Creator',
      showtitle:"Solana Token Creator"
    },
  },
  {
    path: '/claim/',
    component: ClaimPage,
    meta: {
      title: 'Minthe: Claim Rewards',
      showtitle:"Claim Rewards"
    },
  },
  {
    path: '/manage/',
    component: TokenManagePage,
    meta: {
      title: 'Minthe: Token Manage',
      showtitle:"Token Manage"
    },
  },
  {
    path: '/demo/',
    component: DemoPage,
    meta: {
      title: 'Minthe: Test Demo',
      showtitle:"Test Demo"
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to,from,next)=>{
  if (Object.prototype.hasOwnProperty.call(from.query, 'c')) {
    if(from.query['c']){
      useStore().use_invite_code = from.query['c'] as string
    }
  }
  next();
})

export default router
