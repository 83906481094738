import { createHead } from '@vueuse/head'
import { createPinia } from 'pinia'
import { createApp, markRaw } from 'vue'
import App from './App.vue'
import './assets/index.postcss'
import router from './router'
import { createI18n } from 'vue-i18n'
import {en} from './langs/en'
import VueSocialSharing from 'vue-social-sharing'

const trans = {
  en:en
}
const i18n = createI18n({
  locale: 'en', // set locale
  allowComposition: true, // you need to specify that!
  fallbackLocale: 'en', // set fallback locale
  messages:trans
})

const head = createHead()
const app = createApp(App)

const pinia = createPinia()
pinia.use(({ store }) => {
  store.router = markRaw(router)
})
app.use(pinia)
app.use(router)
app.use(head)
app.use(i18n)
app.use(VueSocialSharing)
app.mount('#app')
