import * as walletBase from "@solana/wallet-adapter-base";
import { useStore } from '@/store';
import {
    SolflareWalletAdapter,
    PhantomWalletAdapter
} from "@solana/wallet-adapter-wallets";

import init, { delegator_account_len } from "libminthe/libminthe";
import { MintheClient, CreateTokenOutput } from "libminthe/libminthe";
import { VersionedTransaction, Message, Connection, PublicKey, Transaction } from "@solana/web3.js"
import { getInvitionDataByCode } from "./invite";


export type WalletOption = {
    adapter: walletBase.BaseMessageSignerWalletAdapter,
    name: WalletNames,
    network: walletBase.WalletAdapterNetwork
}
type WalletNames = "Solflare" | "Phantom"

function generateWalletByName(name: WalletNames, network: walletBase.WalletAdapterNetwork) {
    const wallet_map = {
        "Solflare": new SolflareWalletAdapter({ network }),
        "Phantom": new PhantomWalletAdapter()
    }
    return wallet_map[name as WalletNames] as walletBase.BaseMessageSignerWalletAdapter
}

export function generateWallets(is_debug: boolean) {
    let wallets: Array<WalletOption> = [];
    const generate_wallets_with_network = (network: walletBase.WalletAdapterNetwork) => {
        wallets = ["Solflare", "Phantom"].map((value) => {
            return {
                adapter: generateWalletByName(value as WalletNames, network),
                name: value as WalletNames,
                network
            }
        })
    }
    generate_wallets_with_network(is_debug ? walletBase.WalletAdapterNetwork.Devnet : walletBase.WalletAdapterNetwork.Mainnet)
    return wallets;
}

export async function connectWallet(wallet: WalletOption) {
    await wallet.adapter.connect()
    if (useStore().minthe_context == undefined) {
        if (wallet.adapter.publicKey) {
            await init()
            useStore().minthe_context = new MintheClient(wallet.adapter.publicKey.toBase58())
            await fetchRecommenderAndSet()
        }
    }
}
export async function disconnectWallet(wallet: WalletOption) {
    await wallet.adapter.disconnect()
}

export async function switchWalletNetwork(wallet: WalletOption) {
    const dest_network = wallet.network === walletBase.WalletAdapterNetwork.Devnet ? walletBase.WalletAdapterNetwork.Mainnet : walletBase.WalletAdapterNetwork.Devnet
    await wallet.adapter.disconnect()
    wallet.adapter = generateWalletByName(wallet.name, dest_network)
    await wallet.adapter.connect()
    wallet.network = dest_network
}

export type MintInterfaceArgs = {
    token_name: string,
    symbol: string,
    url: string,
    supply: number,
    decimals: number,

    revoke_mint_tokens: boolean,
    revoke_freeze_account: boolean,
    create_delegate: boolean
}

export type UpdateInterfaceArgs = {
    mint: string,
    token_name: string,
    symbol: string,
    url: string,
}

export type RevokeInterfaceArgs = {
    mint: string,
    authority: "MintTokens" | "FreezeAccount"
}

async function sendTransaction(connection: Connection, wallet: WalletOption, msg: Uint8Array) {
    let message = Message.from(msg)
    message.recentBlockhash = (await connection.getLatestBlockhash()).blockhash

    let tx: VersionedTransaction | Transaction;
    if (wallet.adapter.supportedTransactionVersions) {
        tx = new VersionedTransaction(message)
    } else {
        tx = Transaction.populate(message)
    }

    try {
        await wallet.adapter.sendTransaction(tx, connection);
    } catch (e: any) {
        if (!(e instanceof walletBase.WalletError)) {
            throw e
        }

        throw e.name
    }
}

export async function fetchRecommenderAndSet() {

    const route = useStore().route
    let code = route.query['c'] as string
    if(!code){
        if(useStore().use_invite_code == ''){
            return
        }else{
            code = useStore().use_invite_code
        }

    }
    let data = await getInvitionDataByCode(code)
    const context = useStore().minthe_context
    if(!context||!data){
        return
    }
    console.log(`fetch data with code:${code},and set recommander:${data.pubkey}`)
    context.recommender = data.pubkey
}


export async function createToken(wallet: WalletOption, args: MintInterfaceArgs): Promise<string> {
    let connection = new Connection(`https://api.${wallet.network}.solana.com`)
    const publicKey = wallet.adapter.publicKey
    const context = useStore().minthe_context
    if (publicKey == undefined || context == undefined) {
        throw "WalletNotConnected"
    }

    context.payer = publicKey.toBase58()
    let supply = BigInt(args.supply) * (BigInt(10) ** BigInt(args.decimals));
    if (supply >= BigInt(2) ** BigInt(64)) {
        throw "SupplyOverflow"
    }

    const result: CreateTokenOutput = context.create_token(
        args.token_name, 
        args.symbol, 
        args.url, 
        args.decimals, 
        supply,
        args.revoke_mint_tokens,
        args.revoke_freeze_account,
        args.create_delegate
    )
    // check the mint address if it exists
    let mint_pda = new PublicKey(result.mint);
    let account = await connection.getAccountInfo(mint_pda)
    if (account != null) {
        throw "TokenAddressExists"
    }
    
    // send the transaction
    await sendTransaction(connection, wallet, result.message)
    
    return result.mint
}

export async function getClaimableBalance(wallet: WalletOption): Promise<[number, Uint8Array]> {
    let connection = new Connection(`https://api.${wallet.network}.solana.com`)
    const publicKey = wallet.adapter.publicKey
    const context = useStore().minthe_context

    if (publicKey == undefined || context == undefined) {
        throw "WalletNotConnected"
    }

    let result = context.claim_delegated()
    let minimal_balance = await connection.getMinimumBalanceForRentExemption(delegator_account_len())
    let account_balance = await connection.getBalance(new PublicKey(result.delegated))
    let claimable = 0;
    
    if (account_balance > minimal_balance) {
        claimable = account_balance - minimal_balance;
    }

    return [claimable, result.message]
}

export async function claimBalance(connection: Connection, wallet: WalletOption) {
    let [claimable, msg] = await getClaimableBalance(wallet);
    console.log("handle claim balance")
    if (claimable === 0) {
        return
    }

    await sendTransaction(connection, wallet, msg);
}

export async function createInviteLink(connection: Connection, wallet: WalletOption) {
    const publicKey = useStore().activate_wallet?.adapter.publicKey
    const context = useStore().minthe_context

    if (publicKey == undefined || context == undefined) {
        throw "WalletNotConnected"
    }

    let result = context.create_delegate();
    // perform checks
    let delegate = await connection.getAccountInfo(new PublicKey(result.delegated))
    if (delegate) {
        throw "InvideLinkAlreadyCreated"
    }
    console.log(result.message)
    await sendTransaction(connection, wallet, result.message)
}

export async function updateMetadata(wallet: WalletOption, args: UpdateInterfaceArgs) {
    let connection = new Connection(`https://api.${wallet.network}.solana.com`)
    const publicKey = wallet.adapter.publicKey
    const context = useStore().minthe_context

    if (publicKey == undefined || context == undefined) {
        throw "WalletNotConnected"
    }

    context.payer = publicKey.toBase58()
    const result = context.update_metadata(args.mint, args.token_name, args.symbol, args.url)
    // check the mint address if it exists
    let mint_pda = new PublicKey(args.mint);
    let supply = await connection.getAccountInfo(mint_pda)
    if (supply == null) {
        throw "TokenNotExist"
    }
    
    // send the transaction
    await sendTransaction(connection, wallet, result)
}

export async function revokeAuthority(wallet: WalletOption, args: RevokeInterfaceArgs) {
    let connection = new Connection(`https://api.${wallet.network}.solana.com`)
    const publicKey = wallet.adapter.publicKey
    const context = useStore().minthe_context

    if (publicKey == undefined || context == undefined) {
        throw "WalletNotConnected"
    }

    context.payer = publicKey.toBase58()
    const result = context.revoke_authority(args.mint, args.authority)
    // check the mint address if it exists
    let mint_pda = new PublicKey(args.mint);
    let supply = await connection.getAccountInfo(mint_pda)
    if (supply == null) {
        throw "TokenNotExist"
    }

    // send the transaction
    await sendTransaction(connection, wallet, result)
}

export async function withdrawFee(wallet: WalletOption) {
    let connection = new Connection(`https://api.${wallet.network}.solana.com`)
    const publicKey = wallet.adapter.publicKey
    const context = useStore().minthe_context

    if (publicKey == undefined || context == undefined) {
        throw "WalletNotConnected"
    }

    context.payer = publicKey.toBase58()
    const result = context.withdraw_fee();

    // send the transaction
    await sendTransaction(connection, wallet, result)
}

